.body--werkliste {

    .main {
        padding-top: 75px;
    }
}


.werkliste {
    font-size: 15px;
    line-height: 18px;
    font-weight: 700;

    &__projekt {
        display: flex;
        width: 100%;
        flex-wrap: wrap;
        position: relative;
        margin-bottom: 100px;

        &:last-child {
            margin-bottom: 0;
        }
    }

    &__titel {
        font-size: 15px;
        line-height: 18px;
    }

    &__jahr-container {
        font-size: 15px;
        line-height: 20px;
        height: 100%;
    }

    &__jahr {
        position: sticky;
        left: 0;
    }

    &__nummer, &__ort {
        font-size: 10px;
        line-height: 18px;
    }

    &__titel-container, &__ort, &__nummer {
        margin-bottom: 10px;
    }

    &__zum-projekt {
        font-size: 10px;
        line-height: 18px;
        color: #000;
        position: relative;
        // font-size: font-size: zum-projekt - titel
        top: calc(10px - 15px + 2px);
    }

    &__bild {
        display: none;
    }
}

@include mediaquery(smartphone) {

    $layout: smartphone;

    .body--werkliste {

        .main {
            padding-top: groesse($layout, 260 - 20);
        }
    }

    .werkliste {
        display: flex;

        &__projekt {
            grid-column: 1 / 5;
        }

        &__jahr-container {
            position: absolute;
            top: 0;
            left: 0;
            width: spalten($layout, 1, 4, 0, 0);
        }

        &__nummer {
            @include abstand($layout, margin, bottom, 5);
            margin-left: spalten($layout, 1, 4, 0, 1);
            width: spalten($layout, 1, 4, 0, 0);
            margin-right: spalten($layout, 0, 4, 0, 2);
        }
        
        &__ort {
            width: spalten($layout, 2, 4, 0, 0);
        }

        &__titel-container {
            margin-left: spalten($layout, 1, 4, 0, 1);
            width: spalten($layout, 4, 4, 0, 0);
            display: flex;
            flex-wrap: wrap;
        }

        &__jahr {
            top: 75px;
        }

        &__titel {
            width: 100%;
        }
    }
}

@include mediaquery(tabletHoch) {

    $layout: tabletHoch;

    .werkliste {
        display: flex;

        &__projekt {
            grid-column: 1 / 6;
        }

        &__jahr-container {
            position: absolute;
            top: 0;
            left: 0;
            width: spalten($layout, 1, 5, 0, 0);
        }

        &__nummer {
            @include abstand($layout, margin, bottom, 5);
            margin-left: spalten($layout, 1, 5, 0, 1);
            width: spalten($layout, 1, 5, 0, 0);
            margin-right: spalten($layout, 0, 5, 0, 2);
        }
        
        &__ort {
            width: spalten($layout, 3, 5, 0, 0);
        }

        &__titel-container {
            margin-left: spalten($layout, 1, 5, 0, 1);
            width: spalten($layout, 4, 5, 0, 0);
            display: flex;
            flex-wrap: wrap;
        }

        &__jahr {
            top: 75px;
        }


        &__titel {
            width: 100%;
        }
    }
}

@include mediaquery(tabletQuer) {

    $layout: tabletQuer;

    .werkliste {
        display: flex;

        &__projekt {
            grid-column: 1 / 9;
        }

        &__jahr-container {
            position: absolute;
            top: 0;
            left: 0;
            width: spalten($layout, 1, 8, 0, 0);
        }

        &__nummer {
            margin-left: spalten($layout, 1, 8, 0, 1);
        }

        &__nummer,
        &__ort {
            width: spalten($layout, 1, 8, 0, 0);
            margin-right: spalten($layout, 0, 8, 0, 2);
        }

        &__titel-container {
            display: flex;
            flex-wrap: wrap;
            width: spalten($layout, 5, 8, 0, 0);
        }

        &__jahr {
            top: 75px;
        }


        &__titel {
            width: 100%;
        }
    }
}

@include mediaquery(desktop) {

    $layout: desktop;

    .werkliste {
        display: flex;

        &__projekt {
            grid-column: 1 / 12;
        }

        &__jahr-container {
            position: absolute;
            top: 0;
            left: 0;
            width: spalten($layout, 1, 11, 0, 0);
        }

        &__nummer {
            margin-left: spalten($layout, 1, 11, 0, 1);
        }

        &__nummer,
        &__ort {
            width: spalten($layout, 1, 11, 0, 0);
            margin-right: spalten($layout, 0, 11, 0, 2);
        }

        &__titel-container {
            margin-bottom: 15px;
            display: flex;
            width: spalten($layout, 8, 11, 0, 0);
        }

        &__jahr {
            top: 75px;
        }

        &__zum-projekt-container {
            margin-left: 7px;
            position: relative;
        }

        &__zum-projekt {

            &:hover {
                + .werkliste__bild {
                    display: block;
                }
            }
        }

        &__bild {
            position: absolute;
            top: calc(15px + 5px);
            left: 0;
            // 170px
            width: 11.806vw;
            display: none;
            z-index: 10;
        }
    }
}

@include mediaquery(gross) {

    $layout: gross;

    .werkliste {
        display: flex;

        &__projekt {
            grid-column: 1 / 18;
        }

        &__jahr-container {
            position: absolute;
            top: 0;
            left: 0; 
            width: spalten($layout, 1, 17, 0, 0);
        }

        &__jahr {
            top: 75px;
        }

        &__nummer {
            margin-left: spalten($layout, 1, 17, 0, 1);
            padding-bottom: groesse($layout, 17);
        }

        &__nummer,
        &__ort {
            width: spalten($layout, 1, 17, 0, 0);
            margin-right: spalten($layout, 0, 17, 0, 2);
        }

        &__titel-container {
            margin-bottom: 15px;
            display: flex;
            width: spalten($layout, 14, 17, 0, 0);
        }

        &__zum-projekt-container {
            margin-left: 7px;
            position: relative;
        }

        &__zum-projekt {

            &:hover {
                + .werkliste__bild {
                    display: block;
                }
            }
        }

        &__bild {
            position: absolute;
            top: calc(15px + 5px);
            left: 0;
            // 170px
            width: 6.641vw;
            display: none;
            z-index: 10;
        }
    }
}