.main {
    position: relative;
    font-family: 'MessinaSans', sans-serif;
    display: grid;
    column-gap: 16px;
    padding: 0 20px 0 20px;
}

@include mediaquery(smartphone) {
    
    $layout: smartphone;
    
    .main {
        column-gap: 11px;
        padding-top: 240px;
        grid-template-columns: repeat(4, 1fr);
    }
}

@include mediaquery(tabletHoch) {
    
    $layout: tabletHoch;
    
    .main {
        padding-top: 0px;
        grid-template-columns: repeat(6, 1fr);
    }
}

@include mediaquery(tabletQuer) {
    
    $layout: tabletQuer;
    
    .main {
        padding-top: 0px;
        grid-template-columns: repeat(9, 1fr);
    }
}

@include mediaquery(desktop) {
    
    $layout: desktop;
    
    .main {
        padding-top: 82px;
        grid-template-columns: repeat(12, 1fr);
    }
}

@include mediaquery(gross) {
    
    $layout: gross;
    
    .main {
        padding-top: 0px;
        grid-template-columns: repeat(18, 1fr);
    }
}