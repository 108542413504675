.body--startseite .main {
    margin-top: 82px;
    min-height: 100vh;
    grid-auto-rows: max-content;
}

.projekte {

    &__grain-overlay {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        pointer-events: none;
        mix-blend-mode: overlay;
        opacity: 0;
        transition: opacity 0.3s;
        z-index: 1000;
    }

    &__titel-container {
        direction: ltr;
        overflow: hidden;
        position: fixed;
        top: 21.50%;
        right: -22px;
        width: 100%;
        height: 110px;
        z-index: 1;
    }

    &__projekt {
        grid-column: span 3;
        margin-bottom: 27.5%;
        text-decoration: none;
        width: 50%;
        position: relative;

        &:first-child {
            padding-top: 0;
        }

        .projekte__grain {
            opacity: 0;
            mix-blend-mode: hard-light;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-image: url('/bausteine/allgemein/allgemein__grain.png');
            pointer-events: none;
            transform-origin: right bottom;
            transition: filter 0.5s ease, opacity 0.5s ease;
        }

        &[data-hover="true"] .projekte__bild-bild {
            transform: scale(2.35);
            transform-origin: left top;
            z-index: 10;
            filter: none;
        }

        &[data-grain="true"] .projekte__bild-bild {
            transition: transform 0.5s ease, filter 0.3s ease;
            transform: scale(1);
            transform-origin: left top;
            transition: filter 0.3s ease;
        }

        &[data-grain="true"] .projekte__grain {
            opacity: 1;
            transition: opacity 0.3s ease;
        }

        &[data-grain="true"] .projekte__beschriftung {
            filter: blur(2px);
            opacity: 0.7;
            transform-origin: right bottom;
            transition: filter 0.5s ease, opacity 0.5s ease;
        }
    }

    &__bild {
        width: 100%;
        filter: none;
        position: relative;
    }

    &__bild-bild {
        pointer-events: none;
    }

    &__beschriftung {
        width: 100%;
        text-decoration: none;

        &--desktop {
            flex: 1;
            display: flex;
            flex-wrap: wrap;
            flex-direction: column;
        }

        &--smartphone {
            display: none;
        }
    }

    &__beschreibung {
        font-size: 10px;
        line-height: 12px;
        font-weight: 700;
        margin: 0 -5px 20px -5px;
        width: 100%;
        text-decoration: none;
    }


    &__text {
        margin: 0 5px 0 5px;
        text-decoration: none;
        color: #000;
    }

    &__titel {
        font-size: 15px;
        font-weight: 700;
        line-height: 18px;
        padding-bottom: 12px;
        text-decoration: none;
        color: #000;
        width: 100%;
    }
}

@include mediaquery(smartphone) {

    $layout: smartphone;

    .body--startseite .main {
        direction: rtl;
    }

    .projekte {
        @include abstand($layout, margin, top, 147);

        &__projekt {
            direction: ltr;
            filter: blur(2px);
            width: 100%;

            &:first-child {
                padding-top: 0;
            }

            &:hover {
                z-index: 0;

                .projekte__bild {
                    transform: scale(100%);
                    transform-origin: left top;
                }
            }


            .projekte__grain {
                transition: none;
            }
        }

        &__beschriftung {
            height: 100%;
            width: spalten($layout, 3, 4, 4, 0);
            position: absolute;
            top: 0;
            left: 0;
        }

        &__beschriftung {
            &--desktop {
                display: none;
            }

            &--smartphone {
                display: block;
            }
        }

        &__titel {

            &--desktop {
                display: none;
            }
        }

        &__bild {
            width: 100%;
        }
    }
}

@include mediaquery(tabletHoch) {

    $layout: tabletHoch;

    .projekte {
        &.main {
            padding-bottom: 40vw;
        }

        &__projekt {
            display: flex;
            flex-direction: column;
        }

        &__beschreibung {
            display: flex;
            flex-wrap: wrap;
        }

        &__titel {
            &--desktop {
                display: block;
            }
        }

        &__titel-container {
            &--smartphone {
                display: none;
            }
        }
    }
}

@include mediaquery(tabletQuer) {

    $layout: tabletQuer;

    .projekte {
        &.main {
            padding-bottom: 30vw;
        }

        &__projekt {
            display: flex;
            flex-direction: column;
        }

        &__beschreibung {
            display: flex;
            flex-wrap: wrap;
        }

        &__titel {
            &--desktop {
                display: block;
            }
        }

        &__titel-container {
            &--smartphone {
                display: none;
            }
        }
    }
}

@include mediaquery(desktop) {

    $layout: desktop;

    .projekte {
        &.main {
            padding-bottom: 22vw;
        }

        &__projekt {
            display: flex;
            flex-direction: column;
        }

        &__beschreibung {
            display: flex;
            flex-wrap: wrap;
        }

        &__titel {
            &--desktop {
                display: block;
            }
        }

        &__titel-container {
            &--smartphone {
                display: none;
            }
        }
    }
}

@include mediaquery(gross) {

    $layout: gross;

    .projekte {

        &__projekt {
            display: flex;
            flex-direction: column;
        }

        &__beschreibung {
            display: flex;
            flex-wrap: wrap;
        }

        &__titel {
            &--desktop {
                display: block;
            }
        }

        &__titel-container {
            &--smartphone {
                display: none;
            }
        }
    }
}