.body--haltung {
    grid-auto-rows: max-content;
    background-color: #0B0B0B;
    color: #fff;

    .main {
        padding-top: 20px;
        min-height: 100vh;
    }
}


.haltung {
    font-size: 15px;
    line-height: 18px;
    font-weight: 700;
    grid-auto-rows: max-content;

    &__texte {
        grid-column: 2 / 7;
        width: 100%;
    }

    &__text {
        background-color: #0B0B0B;
        padding-top: 200px;

        &:first-child {
            padding-top: 0;
        }
    }
}

@include mediaquery(smartphone) {

    $layout: smartphone;

    .body--haltung {
    
        .main {
            padding-top: groesse($layout, 260 - 20);
        }
    }

    .haltung {

        &__texte {
            grid-column: 1 / 4;
            width: 100%;
        } 
    }
}

@include mediaquery(tabletHoch) {

    $layout: tabletHoch;

    .haltung {
        &__texte {
            grid-column: 2 / 6;
            width: 100%;
        } 
    }
}

@include mediaquery(tabletQuer) {

    $layout: tabletQuer;

    .haltung {}
}

@include mediaquery(desktop) {

    $layout: desktop;

    .haltung {
    }
}

// @include mediaquery(gross) {

//     $layout: gross;

//     .haltung {}
// }