.header {
    display: grid;
    column-gap: 16px;
    position: fixed;
    left: 0;
    top: 20px;
    padding: 0 20px;
    width: 100%;
    z-index: 100;

    &__element-link {
        background: linear-gradient(0deg, white 0%, white 50%, #000 50%, #000 100%);
        text-decoration: none;
        background-clip: text;
        background-size: 100% 200%;
        background-position-y: 0;
        color: transparent;
    }

    &__element {
        &--logo {
            font-size: 15px;
            line-height: 18px;
        }

        &--projekt {
            display: none;
            grid-column-start: 3;
            grid-column-end: -2;
            margin: 0 -5px 0 -5px;
            
            .body--projekte-entry & {
                display: block;
            }
        }
    }
    
    &__menuepunkt {
        cursor: pointer;
        margin: 0 5px 0 5px;
        font-size: 15px;
        line-height: 18px;
        font-weight: 700;
        filter: blur(2px);
        color: transparent;
        background: linear-gradient(0deg, white 0%, white 50%, #000 50%, #000 100%);
        text-decoration: none;
        background-clip: text;
        -webkit-background-clip: text;
        background-size: 100% 200%;
        background-position-y: 0;
        
        &[data-menue-projekt="aktiv"], &:hover {
            filter: blur(0px);
        }
    }

    &__beschriftung {
        position: sticky;
        margin-top: 33px;
    }

    &__beschreibung {
        font-size: 10px;
        line-height: 12px;
        font-weight: 700;
        display: flex;
        flex-wrap: wrap;
        margin: 0 -5px 0 -5px;
        width: 100%;
    }

    &__text {
        margin: 0 5px 0 5px;
        text-decoration: none;
        color: #000;
    }

    &__titel {
        font-size: 15px;
        font-weight: 700;
        line-height: 18px;
        padding-bottom: 12px;
        width: 50%;
        text-decoration: none;
        color: #000;
        width: 100%;
    }
}

.header__element--logo {
    .body--haltung &, .body--kontakt & {
        color: #fff;
    }
}


@include mediaquery(smartphone) {

    $layout: smartphone;

    .header {
        padding-top: 0px;
        grid-template-columns: repeat(4, 1fr);
        column-gap: 11px;

        &__element {

            &--projekt {
                padding-top: groesse($layout, 10);
            }

            &--menue {
                grid-column-start: -1;
                grid-column-end: -2;
            }

            &--projekt {
                grid-column-start: 1;
                grid-column-end: -2;
            }
        }
    }
}

@include mediaquery(tabletHoch) {

    $layout: tabletHoch;

    .header {
        padding-top: 0px;
        grid-template-columns: repeat(6, 1fr);

        &__element {

            &--menue {
                grid-column-start: -1;
                grid-column-end: -2;
            }
        }
    }
}


@include mediaquery(tabletQuer) {

    $layout: tabletQuer;

    .header {
        padding-top: 0px;
        grid-template-columns: repeat(9, 1fr);

        &__element {

            &--menue {
                grid-column-start: -1;
                grid-column-end: -2;
            }
        }
    }
}


@include mediaquery(desktop) {

    $layout: desktop;

    .header {
        padding-top: 0px;
        grid-template-columns: repeat(12, 1fr);

        &__element {

            &--menue {
                grid-column-start: -1;
                grid-column-end: -2;
            }
        }
    }
}


@include mediaquery(gross) {

    $layout: gross;

    .header {
        padding-top: 0px;
        grid-template-columns: repeat(18, 1fr);

        &__element {

            &--menue {
                grid-column-start: -1;
                grid-column-end: -2;
            }
        }
    }
}