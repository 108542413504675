.cursor {
    display: none;
    font-family: ROM, sans-serif;
    font-weight: 400;
    left: 0;
    position: fixed;
    pointer-events: none;
    top: 0;

    &--symbol {
        z-index: 5001;
    }

    &__inner {
        position: absolute;
        left: 0;
        top: 0;
        transform: translate(-50%, -50%);
        transition:
            width 0.5s,
            height 0.5s,
            transform 0.5s;

        &--symbol {
            background-repeat: no-repeat;
            background-position: center center;
            background-size: 50%;
            align-items: center;
            display: flex;
            justify-content: center;
        }
    }

    body[data-cursor-touch="cursor"] & {
        display: block;
    }

    body[data-cursor-touch="touch"] & {
        display: none;
    }

    body & {

        &--symbol {
            mix-blend-mode: difference;
        }

        &__inner {
            width: 15px;
            height: 15px;

            background-color: #fff;
        }
    }

    body[data-body-cursor="kreuz"][data-cursor-touch="cursor"] & {

        &__inner {
            width: 15px;
            height: 15px;
            position: relative;
            transform: translate(0%, 0%);
            // Quadrat verstecken
            background-color: transparent; 

            &--symbol {
                mix-blend-mode: difference;

                &::before,
                &::after {
                    content: '';
                    position: absolute;
                    background-color: #fff;
                }

                &::before {
                    width: 1px;
                    height: 15px;
                    top: 0;
                    left: 50%;
                }

                &::after {
                    width: 15px;
                    height: 1px;
                    top: 50%;
                    left: 0;
                }
            }
        }
    }
}