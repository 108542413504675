.menue {
    font-family: 'MessinaSans', sans-serif;
    font-size: 15px;
    line-height: 18px;
    height: 100%;
    width: 100%;
    position: relative;
    font-weight: 700;

    &__links {
        position: fixed;
        text-decoration: none;
        top: 20px;
    }

    &__link {
        cursor: pointer;
        display: inherit;
        text-decoration: none;
        filter: blur(2px);
        // transition: 0.3s;
        margin-top: 12px;

        background: linear-gradient(0deg, white 0%, white 50%, #0B0B0B 50%, #0B0B0B 100%);
        color: transparent;
        text-decoration: none;
        background-clip: text;
        -webkit-background-clip: text;
        background-size: 100% 200%;
        background-position-y: 0;

        &:first-child {
            margin-top: 0;
        }

        .body--haltung &,
        .body--kontakt & {
            background: linear-gradient(0deg, #0B0B0B 0%, #0B0B0B 50%, white 50%, white 100%);
            color: transparent;
            text-decoration: none;
            background-clip: text;
            -webkit-background-clip: text;
            background-size: 100% 200%;
            background-position-y: 0;
        }

        &--aktiv {
            filter: blur(0);
        }

        &:hover {
            // transition: 0.3s;
            filter: blur(0);
        }
    }
}