.projekt {

    &__projekte {
        display: grid;
        row-gap: 0;
        column-gap: 16px;
        padding: 0 20px;
    }

    &__projekt--konzept {
        display: flex;
        flex-wrap: nowrap;
        width: calc(100% + 40px);
        overflow-x: hidden;
        scroll-snap-align: start;
    }

    &__menue {
        position: sticky;
        left: 0;
        z-index: 100000;
    }

    &__beschriftung {
        z-index: 100;
        margin: 0 20px;
        color: transparent;
        background: linear-gradient(0deg, white 0%, white 50%, #000 50%, #000 100%);
        text-decoration: none;
        background-clip: text;
        -webkit-background-clip: text;
        background-size: 100% 200%;
        background-position-y: 0;

        a {
            z-index: 100;
            color: transparent;
            text-decoration: none;
            background-clip: text;
            -webkit-background-clip: text;
            background-size: 100% 200%;
            background-position-y: 0;
        }
    }

    &__beschreibung {
        font-size: 10px;
        line-height: 12px;
        font-weight: 700;
        display: flex;
        flex-wrap: wrap;
        margin: 0 -5px 3px -5px;
    }


    &__text {
        margin: 0 5px 0 5px;
        text-decoration: none;
    }

    &__titel {
        font-size: 15px;
        font-weight: 700;
        line-height: 18px;
        text-decoration: none;
    }

    &__projektinfo {
        font-size: 10px;
        margin-top: 4px;
        line-height: 12px;
        font-weight: 700;

        a {
            text-decoration: underline;
        }
    }

    &__bau-bild {
        margin: 0;
    }

    &__bau-video-container,
    &__entstehung-video-container {
        overflow: hidden;
        position: relative;
        width: 100%;
        height: 0;
    }
    
    &__bau-video,
    &__entstehung-video {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    &__konzept-wrap {
        display: flex;
        padding: 80px 0px 80px 0px;
        height: 80vh;
        background-color: #F2F2F2;
        width: 100%;
    }

    &__konzept-video-container {
        overflow: hidden;
        position: relative;
        width: 100%;
        height: calc(80vh - 160px);
        margin: 0px 30px 0px 30px;
        mix-blend-mode: darken;
    }

    &__konzept-video {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    &__konzept-bild {
        margin: 0px 30px 0px 30px;
        height: calc(80vh - 160px);
        mix-blend-mode: darken;

        .macro-bild__picture {
            height: 100%;
            position: relative;
            width: auto;
        }
    }

    &__konzept-bild-bild.macro-bild {
        height: 100%;
        width: auto;
    }

    &__konzept-bild-img.macro-bild__img {
        position: relative;
        height: 100%;
        width: auto;
    }

    &__bildquelle {
        font-size: 10px;
        line-height: 12px;
        font-weight: 700;
        color: rgb(182, 182, 182);
        margin: 8px 0 0 0;
    }

    &__projekt {
        column-gap: 16px;

        &--konzept {
            margin: 0px -20px 0 -20px;
        }
    }

    &__projekt-container {
        display: inline-grid;
        column-gap: 16px;
        padding: 0px 20px 0 20px;

        &--entstehung {
            margin: 0px -20px 0 -20px;
            background-color: black;
        }
    }
}

@include mediaquery(smartphone) {

    $layout: smartphone;

    .projekt {

        &__projekte {
            grid-template-columns: repeat(4, 1fr);
        }

        &__beschriftung {
            @include abstand($layout, margin, top, 147);
        }

        &__projekt {
            grid-column-start: 1;
            grid-column-end: 5;
            column-gap: 11px;

            display: grid;

            &--bau,
            &--entstehung {
                @include abstand($layout, padding, top, 62);
                grid-column-start: 1;
                grid-column-end: 5;
                grid-template-columns: repeat(28, 1fr);
            }
        }

        &__projekt-container {
            grid-template-columns: repeat(4, 1fr);
            column-gap: 11px;

            &--entstehung {
                grid-column-start: 1;
                grid-column-end: -1;
            }
        }

        &__menue {
            top: groesse($layout, 18);
        }

        &__bau-video-container,
        &__entstehung-video-container {
            @include abstand($layout, margin, bottom, 158);
        }

        &__bau-bild,
        &__entstehung-bild {
            @include abstand($layout, padding, bottom, 158);

            &--hauptbild-bildXlQuer {
                grid-column-start: 1;
                grid-column-end: -1;
            }

            &--hauptbild-bildXlHoch {
                grid-column-start: 1;
                grid-column-end: 22;
            }

            &--bildXlHoch {
                grid-column-start: 1;
                grid-column-end: 22;
            }


            &--bildXlHoch-0 {
                grid-column-start: 19;
                grid-column-end: -1;
            }

            &--bildQuer {
                grid-column-start: 1;
                grid-column-end: 19;
            }

            &--bildQuer-0 {
                grid-column-start: 11;
                grid-column-end: -1;
            }

            &--bildM-1 {
                grid-column-start: 1;
                grid-column-end: 12;
            }

            &--bildM-2 {
                grid-column-start: 18;
                grid-column-end: -1;
            }

            &--bildL-LS {
                grid-column-start: 1;
                grid-column-end: 19;
            }

            &--bildS-LS {
                grid-column-start: 22;
                grid-column-end: -1;
            }

            &--bildL-SL {
                grid-column-start: 11;
                grid-column-end: -1;
            }

            &--bildS-SL {
                grid-column-start: 1;
                grid-column-end: 8;
            }
        }

        &__konzept-wrap {
            // Bildabstand(30px) / 2 + 10px / 2
            margin-left: calc((-30px / 2) + 10px / 2);
            // Bildabstand(30px) / 2 + 10px / 2
            margin-right: calc((-30px / 2) + 10px / 2);
        }
    }
}

@include mediaquery(tabletHoch) {

    $layout: tabletHoch;

    .projekt {

        &__projekte {
            grid-template-columns: repeat(6, 1fr);
        }

        &__beschriftung {
            @include abstand($layout, padding, top, 62);
        }

        &__projekt {
            grid-column-start: 1;
            grid-column-end: -1;

            display: grid;

            &--bau,
            &--entstehung {
                @include abstand($layout, padding, top, 46);
                grid-column-start: 1;
                grid-column-end: -2;
                grid-template-columns: repeat(35, 1fr);
            }
        }

        &__projekt-container {
            grid-template-columns: repeat(6, 1fr);

            &--entstehung {
                grid-column-start: 1;
                grid-column-end: -1;
            }
        }

        &__menue {
            top: groesse($layout, 18);
        }

        &__bau-video-container,
        &__entstehung-video-container {
            @include abstand($layout, margin, bottom, 158);
        }

        &__bau-bild,
        &__entstehung-bild {
            @include abstand($layout, padding, bottom, 158);

            &--hauptbild-bildXlQuer {
                grid-column-start: 1;
                grid-column-end: -1;
            }

            &--hauptbild-bildXlHoch {
                grid-column-start: 1;
                grid-column-end: 26;
            }

            &--bildXlHoch {
                grid-column-start: 1;
                grid-column-end: 26;
            }


            &--bildXlHoch-0 {
                grid-column-start: 11;
                grid-column-end: -1;
            }

            &--bildQuer {
                grid-column-start: 1;
                grid-column-end: 26;
            }

            &--bildQuer-0 {
                grid-column-start: 11;
                grid-column-end: -1;
            }

            &--bildM-1 {
                grid-column-start: 1;
                grid-column-end: 15;
            }

            &--bildM-2 {
                grid-column-start: 22;
                grid-column-end: -1;
            }

            &--bildL-LS {
                grid-column-start: 1;
                grid-column-end: 26;
            }

            &--bildS-LS {
                grid-column-start: 29;
                grid-column-end: -1;
            }

            &--bildL-SL {
                grid-column-start: 11;
                grid-column-end: -1;
            }

            &--bildS-SL {
                grid-column-start: 1;
                grid-column-end: 8;
            }
        }

        &__konzept-wrap {
            // Bildabstand(30px) / 2 + 10px / 2
            margin-left: calc((-30px / 2) + 10px / 2);
            // Spaltenabstand(16px) + Spaltenbreite(108) - 10px
            padding-right: calc(2.083vw + 14.063vw - 10px);
        }
    }
}

@include mediaquery(tabletQuer) {

    $layout: tabletQuer;

    .projekt {

        &__projekte {
            grid-template-columns: repeat(9, 1fr);
        }

        &__beschriftung {
            position: sticky;
            top: groesse($layout, 62);
            left: groesse($layout, 16);
            width: spalten($layout, 2, 9, 2, 0);
        }

        &__projekt {
            grid-column-start: 1;
            grid-column-end: -1;

            display: grid;
            
            &--bau,
            &--entstehung {
                @include abstand($layout, padding, top, 62);
                grid-column-start: 3;
                grid-column-end: -2;
                grid-template-columns: repeat(30, 1fr);
            }

            &--konzept {
                grid-template-columns: 1fr;
            }
        }


        &__projekt-container {
            grid-template-columns: repeat(9, 1fr);

            &--entstehung {
                grid-column-start: 1;
                grid-column-end: -1;
            }
        }

        &__menue {
            top: groesse($layout, 18);
        }

        &__bau-video-container,
        &__entstehung-video-container {
            @include abstand($layout, margin, bottom, 158);
        }

        &__bau-bild,
        &__entstehung-bild {
            @include abstand($layout, padding, bottom, 158);

            &--hauptbild-bildXlQuer {
                grid-column-start: 1;
                grid-column-end: -1;
            }

            &--hauptbild-bildXlHoch {
                grid-column-start: 1;
                grid-column-end: 21;
            }

            &--bildXlHoch {
                grid-column-start: 1;
                grid-column-end: 21;
            }


            &--bildXlHoch-0 {
                grid-column-start: 11;
                grid-column-end: -1;
            }

            &--bildQuer {
                grid-column-start: 1;
                grid-column-end: 21;
            }

            &--bildQuer-0 {
                grid-column-start: 11;
                grid-column-end: -1;
            }

            &--bildM-1 {
                grid-column-start: 1;
                grid-column-end: 14;
            }

            &--bildM-2 {
                grid-column-start: 18;
                grid-column-end: -1;
            }

            &--bildL-LS {
                grid-column-start: 1;
                grid-column-end: 21;
            }

            &--bildS-LS {
                grid-column-start: 23;
                grid-column-end: -1;
            }

            &--bildL-SL {
                grid-column-start: 11;
                grid-column-end: -1;
            }

            &--bildS-SL {
                grid-column-start: 1;
                grid-column-end: 9;
            }

        }

        &__konzept-wrap {
            // Spaltenabstand(16px) * 2 + Spaltenbreite(95) - 10px
            padding-left: calc((1.563vw * 2) + (9.277vw * 2) - 10px);
            // Spaltenabstand(16px) + Spaltenbreite(95) - 10px
            padding-right: calc(1.563vw + 9.277vw - 10px);
        }
    }
}

@include mediaquery(desktop) {

    $layout: desktop;

    .projekt {

        &__projekte {
            grid-template-columns: repeat(24, 1fr);
        }

        &__beschriftung {
            position: sticky;
            top: groesse($layout, 62);
            left: groesse($layout, 16);
            width: spalten($layout, 2, 12, 2, 0);
        }

        &__projekt {
            grid-column-start: 1;
            grid-column-end: -1;

            display: grid;

            &--bau,
            &--entstehung {
                @include abstand($layout, padding, top, 62);
                grid-column-start: 5;
                grid-column-end: -4;
                grid-template-columns: repeat(17, 1fr);
            }

            &--konzept {
                grid-template-columns: 1fr;
            }
        }

        &__projekt-container {
            grid-template-columns: repeat(24, 1fr);

            &--entstehung {
                grid-column-start: 1;
                grid-column-end: -1;
            }
        }

        &__menue {
            top: groesse($layout, 18);
        }

        &__bau-video-container,
        &__entstehung-video-container {
            @include abstand($layout, margin, bottom, 158);
        }

        &__bau-bild,
        &__entstehung-bild {
            @include abstand($layout, padding, bottom, 158);

            &--hauptbild-bildXlQuer {
                grid-column-start: 1;
                grid-column-end: -1;
            }

            &--hauptbild-bildXlHoch {
                grid-column-start: 1;
                grid-column-end: 13;
            }

            &--bildXlHoch {
                grid-column-start: 1;
                grid-column-end: 13;
            }


            &--bildXlHoch-0 {
                grid-column-start: 6;
                grid-column-end: -1;
            }

            &--bildQuer {
                grid-column-start: 1;
                grid-column-end: 13;
            }

            &--bildQuer-0 {
                grid-column-start: 6;
                grid-column-end: -1;
            }

            &--bildM-1 {
                grid-column-start: 1;
                grid-column-end: 8;
            }

            &--bildM-2 {
                grid-column-start: 11;
                grid-column-end: -1;
            }

            &--bildL-LS {
                grid-column-start: 1;
                grid-column-end: 13;
            }

            &--bildS-LS {
                grid-column-start: 15;
                grid-column-end: -1;
            }

            &--bildL-SL {
                grid-column-start: 6;
                grid-column-end: -1;
            }

            &--bildS-SL {
                grid-column-start: 1;
                grid-column-end: 4;
            }
        }

        &__konzept-wrap {
            // Spaltenabstand(16px) * 2 + Spaltenbreite(102) - 8px
            padding-left: calc((1.111vw * 2) + (7.083vw * 2) - 8px);
            // Spaltenabstand(16px) + Spaltenbreite(102) * 1.5 - (8px / 2)
            padding-right: calc(1.111vw + (7.083vw * 1.5) - (8px / 2));
        }
    }
}

@include mediaquery(gross) {

    $layout: gross;

    .projekt {

        &__projekte {
            grid-template-columns: repeat(36, 1fr);
        }

        &__beschriftung {
            position: sticky;
            top: groesse($layout, 62);
            left: groesse($layout, 16);
            width: spalten($layout, 2, 12, 2, 0);
        }

        &__projekt {
            grid-column-start: 1;
            grid-column-end: -1;

            display: grid;

            &--bau,
            &--entstehung {
                @include abstand($layout, padding, top, 62);
                grid-column-start: 5;
                grid-column-end: -4;
                grid-template-columns: repeat(17, 1fr);
            }

            &--konzept {
                grid-template-columns: 1fr;
            }
        }

        &__projekt-container {
            grid-template-columns: repeat(36, 1fr);

            &--entstehung {
                grid-column-start: 1;
                grid-column-end: -1;
            }
        }

        &__menue {
            top: groesse($layout, 18);
        }

        &__bau-video-container,
        &__entstehung-video-container {
            @include abstand($layout, margin, bottom, 158);
        }

        &__bau-bild,
        &__entstehung-bild {
            @include abstand($layout, padding, bottom, 158);

            &--hauptbild-bildXlQuer {
                grid-column-start: 1;
                grid-column-end: -1;
            }

            &--hauptbild-bildXlHoch {
                grid-column-start: 1;
                grid-column-end: 13;
            }

            &--bildXlHoch {
                grid-column-start: 1;
                grid-column-end: 13;
            }


            &--bildXlHoch-0 {
                grid-column-start: 6;
                grid-column-end: -1;
            }

            &--bildQuer {
                grid-column-start: 1;
                grid-column-end: 13;
            }

            &--bildQuer-0 {
                grid-column-start: 6;
                grid-column-end: -1;
            }

            &--bildM-1 {
                grid-column-start: 1;
                grid-column-end: 8;
            }

            &--bildM-2 {
                grid-column-start: 11;
                grid-column-end: -1;
            }

            &--bildL-LS {
                grid-column-start: 1;
                grid-column-end: 13;
            }

            &--bildS-LS {
                grid-column-start: 15;
                grid-column-end: -1;
            }

            &--bildL-SL {
                grid-column-start: 6;
                grid-column-end: -1;
            }

            &--bildS-SL {
                grid-column-start: 1;
                grid-column-end: 4;
            }
        }

        &__konzept-wrap {
            // Spaltenabstand(16px) * 2 + Spaltenbreite(125) - 8px
            padding-left: calc((0.625vw * 2) + (4.879vw * 2) - 8px);
            // Spaltenabstand(16px) * 2 + Spaltenbreite(125) - 8px / 2
            padding-right: calc(0.625vw + (4.879vw * 1.5) - (8px / 2));
        }
    }
}