.error {
    font-family: 'MessinaSans';
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}


@include mediaquery(smartphone) {

    $layout: smartphone;

    .error {
        
   

    }

}


@include mediaquery(desktop) {

    $layout: desktop;

    .error {
        
    }

}